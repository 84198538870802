.Content-textPanelContainer{
    width: 50%;
    margin: 16px;
}

.Content-slidePanelContainer{
    width: 50%;
    margin: 16px;
}

.Content-line{
    width: 2px;
    height: 900px;
    margin: 16px;
    background-color: #333333;
}