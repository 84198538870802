.RichEditor-root {
  background: #fff;
  border: 1px solid #aaa;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
  flex:10;
}

.RichEditor-editor {
  /* border-top: 1px solid #ddd; */
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 0 15px 15px 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 500px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #aaa;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
  flex: 1;
}

.RichEditor-slideElements {
  font-family: 'Helvetica', sans-serif;
  font-size: 8px;
  margin-bottom: 5px;
  user-select: none;
  flex: 1;
  white-space: pre-line;
  text-align: left;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.RichEditor-editor .public-DraftStyleDefault-ul,
.RichEditor-editor .public-DraftStyleDefault-ol {
  margin: 0px 0;
  padding: 0px 0 0 0;
}

.RichEditor-editor .RichEditor-newSlide {
  border-top: 1px solid #aaa;
  margin: 5px 0 0 0;
  padding: 5px 0 0 0;
}

.RichEditor-controlsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.RichEditor-controlsLabel{
  white-space: pre-line;
  text-align: right;
  font-size: x-small;
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
  margin-right: 10px;
  color: #666;
}