.Line{
    color: lightgray;
    /* width: 10px; */
    background-color: lightgray;
    height: 1px;
    border: none;
}

.TextArea{
    width: 100%;
    min-height: 500px;
}

.SideBySide{
    display: flex;
    flex-direction: row;
}