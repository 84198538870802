.Header{
    width: 100%;
    height: 22%;
    background-color: lightskyblue;
    font-weight: bold;
    text-align: left;
    box-sizing:border-box;
    padding: 5px 5px 2px 5px;
    word-wrap: normal;
    font-size: small;
    vertical-align: middle;
}

.SpacerTop{
    height: 10px;
}


.Subheading{
    height: 30px;
    background-color: #E3F1FB;
    margin: 3px;
    box-sizing: border-box;
    position: relative;
}

.SubheadingContent{
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: xx-small;
    text-align: left;
    margin: 4px;
    color: mediumblue;
    font-weight: bold;
}

.Body{
    flex-grow: 10;
    border: lightsteelblue;
    font-size: xx-small;
    text-align: left;
    margin: 3px;
    padding: 2px;
    white-space: pre-line;
}


.Icon{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: mediumblue;
    margin: 5px;
}

.fa{
    color: lightsteelblue;
    margin: 2px;
}

.CustomGraphic{
    background-color: lightgray;
    flex:10;  
    font-size: xx-small;
    text-align: center;
    margin: 3px;
    padding: 2px;
    white-space: pre-line;
}
