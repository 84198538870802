.Container{
    margin:0 5px 0 5px;
}

.PreviewBlock{
    color: gray;
    padding: 0 ;
    
    font-family: 'Georgia', serif;
    font-size: 8px;
    font-style: italic;
    white-space: pre-line;
    display: block;
    width: 100%;
    border: none;

    height: calc(1 * 17.6px);
}




.LineBreak{
border-top: 1px dotted #ddd;
  margin: 5px 5px 0 5px;
  padding: 5px 0 0 0;
}

.lines1{
    height: calc(1 * 17.6px);
}

.lines2{
    height: calc(2 * 17.6px);
}

.lines3{
    height: calc(3 * 17.6px);
}

.lines4{
    height: calc(4 * 17.6px);
}

.lines5{
    height: calc(5 * 17.6px);
}

.lines6{
    height: calc(6 * 17.6px);
}

.lines7{
    height: calc(7 * 17.6px);
}

.lines8{
    height: calc(8 * 17.6px);
}

.lines9{
    height: calc(9 * 17.6px);
}

.lines10{
    height: calc(10 * 17.6px);
}