.NavBar-container {
    height: 50px;
    padding: 10px;
    background-color: #e3f1fb;
    display: flex;
  }

.NavBar-title {
    color: #67a0c9;
    font-size: 36px;
}

.NavBar-logo{
    width: 55px;
    height: 55px;
    margin-right: 5px;
}