.TextArea{
    width: 33%;
    height: 100px;
    padding: 3px;;
}

.SizeSpacer{
    height:0;
    width: 300px;
    padding-top:calc(9/16*100%);
    position: relative;
    margin: 10px;
}

.SizeSpacerInside{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
}

.SlideOutline{
    /* flex-grow: 1; */
    /* flex-basis: calc(100% * (1/2) - 30px - 10px); */
    width: 100%;
    height: 100%;
    outline: lightslategrey;
    outline-style: solid;
    outline-width: 0.5px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}