
.PanelSetup{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1;
}

.IconPanelColumn{
    flex: 1;
    min-width: 0;
    flex-direction: column;
    display: flex;
 
}
