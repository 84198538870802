.TextArea{
    width: 33%;
    height: 100px;
    padding: 3px;;
}

.Grid{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}