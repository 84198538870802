@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
* Welcome to your ✨𝔲𝔱𝔦𝔩𝔦𝔱𝔶 𝔰𝔱𝔶𝔩𝔢𝔰✨!
* This file includes utility classes that are super simple
* and can be used to add general styles; variable definitions
* for colors; and styles to html, body, and other high level
* DOMs.
* 
* All utility classes start with a `u-` and all do
* one basic CSS thing (for example, making the font-weight
* 600 for bolding) or are super generic.
* 
* This is 𝙉𝙊𝙏 the place to define classes for components or
* do rigorous styling. You shoud not need to change this file
* much after initial creation.
*/

:root {
  --primary: #396dff;
  --primary--dim: #6987db;
  --darkgrey: #d4d4d4;
  --medgrey: #e0e0e0;
  --grey: #f7f7f7;
  --white: #fff;

  --xs: 4px;
  --s: 8px;
  --m: 16px;
  --l: 24px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

form {
  flex-grow: 1;
}

.u-flex {
  display: flex;
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
}

.u-flex-justifyCenter {
  justify-content: center;
}

.u-flex-alignCenter {
  align-items: center;
}

.u-inlineBlock {
  display: inline-block;
}

.u-bold {
  font-weight: 600;
}

.u-textCenter {
  text-align: center;
}

.u-relative {
  position: relative;
}

.u-pointer {
  cursor: pointer;
}

.u-link {
  color: #396dff;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.u-link:hover {
  color: #6987db;
  color: var(--primary--dim);
}

.Layout_Content__2hPu8{
    /* margin-top: 16px; */
    display: flex;
    flex-direction: row;
}

.NavBar-container {
    height: 50px;
    padding: 10px;
    background-color: #e3f1fb;
    display: flex;
  }

.NavBar-title {
    color: #67a0c9;
    font-size: 36px;
}

.NavBar-logo{
    width: 55px;
    height: 55px;
    margin-right: 5px;
}
.SlidePanel_Line__2McMo{
    color: lightgray;
    /* width: 10px; */
    background-color: lightgray;
    height: 1px;
    border: none;
}

.SlidePanel_TextArea__3pgA8{
    width: 100%;
    min-height: 150px;
}
.SlidePreview_TextArea__wyteW{
    width: 33%;
    height: 100px;
    padding: 3px;;
}

.SlidePreview_SizeSpacer__3R0Hu{
    height:0;
    width: 300px;
    padding-top:calc(9/16*100%);
    position: relative;
    margin: 10px;
}

.SlidePreview_SizeSpacerInside__2CkEd{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
}

.SlidePreview_SlideOutline__Jyh3B{
    /* flex-grow: 1; */
    /* flex-basis: calc(100% * (1/2) - 30px - 10px); */
    width: 100%;
    height: 100%;
    outline: lightslategrey;
    outline-style: solid;
    outline-width: 0.5px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.SlideElement_Header__BKxBU{
    width: 100%;
    height: 22%;
    background-color: lightskyblue;
    font-weight: bold;
    text-align: left;
    box-sizing:border-box;
    padding: 5px 5px 2px 5px;
    word-wrap: normal;
    font-size: small;
    vertical-align: middle;
}

.SlideElement_SpacerTop__IA-XU{
    height: 10px;
}


.SlideElement_Subheading__2e4wW{
    height: 30px;
    background-color: #E3F1FB;
    margin: 3px;
    box-sizing: border-box;
    position: relative;
}

.SlideElement_SubheadingContent__2dsUk{
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: xx-small;
    text-align: left;
    margin: 4px;
    color: mediumblue;
    font-weight: bold;
}

.SlideElement_Body__3YQbF{
    flex-grow: 10;
    border: lightsteelblue;
    font-size: xx-small;
    text-align: left;
    margin: 3px;
    padding: 2px;
    white-space: pre-line;
}


.SlideElement_Icon__1kvNX{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: mediumblue;
    margin: 5px;
}

.SlideElement_fa__3IZxI{
    color: lightsteelblue;
    margin: 2px;
}

.SlideElement_CustomGraphic__2evem{
    background-color: lightgray;
    flex:10 1;  
    font-size: xx-small;
    text-align: center;
    margin: 3px;
    padding: 2px;
    white-space: pre-line;
}


.SlideLayout_PanelSetup__1bGPz{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1 1;
}

.SlideLayout_IconPanelColumn__jV3qO{
    flex: 1 1;
    min-width: 0;
    flex-direction: column;
    display: flex;
 
}

.SlidePreviews_TextArea__1_c7i{
    width: 33%;
    height: 100px;
    padding: 3px;;
}

.SlidePreviews_Grid__1LsFx{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.TextPanel_Line__24Ing{
    color: lightgray;
    /* width: 10px; */
    background-color: lightgray;
    height: 1px;
    border: none;
}

.TextPanel_TextArea__3bLCW{
    width: 100%;
    min-height: 500px;
}

.TextPanel_SideBySide__5v6Uq{
    display: flex;
    flex-direction: row;
}
.RichEditor-root {
  background: #fff;
  border: 1px solid #aaa;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
  flex:10 1;
}

.RichEditor-editor {
  /* border-top: 1px solid #ddd; */
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 0 15px 15px 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 500px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #aaa;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex: 1 1;
}

.RichEditor-slideElements {
  font-family: 'Helvetica', sans-serif;
  font-size: 8px;
  margin-bottom: 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex: 1 1;
  white-space: pre-line;
  text-align: left;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.RichEditor-editor .public-DraftStyleDefault-ul,
.RichEditor-editor .public-DraftStyleDefault-ol {
  margin: 0px 0;
  padding: 0px 0 0 0;
}

.RichEditor-editor .RichEditor-newSlide {
  border-top: 1px solid #aaa;
  margin: 5px 0 0 0;
  padding: 5px 0 0 0;
}

.RichEditor-controlsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.RichEditor-controlsLabel{
  white-space: pre-line;
  text-align: right;
  font-size: x-small;
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
  margin-right: 10px;
  color: #666;
}
.TemplatePanel_TemplatePanel__37YTP{
    flex:1 1;
    font-size: xx-small;
}

.TemplatePanel_TopSpacer__22S42{
    height: 41.8px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    color: gray;
 
    /* border-bottom: 0.5px solid gray; */
    
}

.TemplatePanel_TemplateSetup__3UgMb{
    /* display: flex;
    flex-direction: column; */
    flex:2 1;
}

.TemplatePanel_ButtonContainer__3uPRz{
    border-top: 1px dotted #ddd;
  margin: 5px 5px 0 5px;
  padding: 15px 0 0 0;
  
}

.TemplatePanel_NewSlideButton__38-cd{
  width: 80px;
  height: 40px;
  background-color: lightsteelblue;
  border-radius: 5%;
  border: none;
  padding: 0;
 
  font-size: 10px;
  color: white;
  text-align: center;
  vertical-align: middle;
  outline: none;

}

.TemplatePanel_NewSlideButton__38-cd:focus{
  outline: none;
  border: none;
}


.TemplatePreview_Container__21krB{
    margin:2px 5px 2px 5px;
}


.TemplatePreview_SelectBlock__2LTlF{
    background-color: #f2f4f7;
    color: gray;
    padding: 0 ;
    
    font-family: 'Helvetica', sans-serif;
    text-align: center;
    font-size: 12px;
    
    white-space: pre-line;
    display: block;
    width: 100%;
    border: none;
}

.TemplatePreview_SelectBlock__2LTlF:hover{
    border: none;
    outline: none;
    background-color: #dbe7f8;
}

.TemplatePreview_SelectBlock__2LTlF:focus{
    border: none;
    outline: none;
    background-color: #f2f4f7;
}


.TemplatePreview_LineBreak__2E40q{
border-top: 1px dotted #ddd;
  margin: 5px 5px 0 5px;
  padding: 5px 0 0 0;
}

.TemplatePreview_lines1__d095k{
    height: calc(1 * 17.6px);
}

.TemplatePreview_lines2__iBioH{
    height: calc(2 * 17.6px);
}

.TemplatePreview_lines3__4BVtQ{
    height: calc(3 * 17.6px);
}

.TemplatePreview_lines4__2B882{
    height: calc(4 * 17.6px);
}

.TemplatePreview_lines5__1baMw{
    height: calc(5 * 17.6px);
}

.TemplatePreview_lines6__14s79{
    height: calc(6 * 17.6px);
}

.TemplatePreview_lines7__b3Ytz{
    height: calc(7 * 17.6px);
}

.TemplatePreview_lines8__gYgoN{
    height: calc(8 * 17.6px);
}

.TemplatePreview_lines9__3owc-{
    height: calc(9 * 17.6px);
}

.TemplatePreview_lines10__3Pqvd{
    height: calc(10 * 17.6px);
}
.KeyPanel_KeyPanel__34XAc{
    flex:1 1;
    font-size: xx-small;
}

.KeyPanel_TopSpacer__NCsTf{
    height: 41.8px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    color: gray;
 
    /* border-bottom: 0.5px solid gray; */
    
}

.KeyPanel_KeySetup__1aXkk{
    /* display: flex;
    flex-direction: column; */
    flex:1 1;
}


.KeyDisplay_Container__eGuQM{
    margin:0 5px 0 5px;
}

.KeyDisplay_PreviewBlock__PxCAl{
    color: gray;
    padding: 0 ;
    
    font-family: 'Georgia', serif;
    font-size: 8px;
    font-style: italic;
    white-space: pre-line;
    display: block;
    width: 100%;
    border: none;

    height: calc(1 * 17.6px);
}




.KeyDisplay_LineBreak__377iO{
border-top: 1px dotted #ddd;
  margin: 5px 5px 0 5px;
  padding: 5px 0 0 0;
}

.KeyDisplay_lines1__1hL-N{
    height: calc(1 * 17.6px);
}

.KeyDisplay_lines2__2UiUi{
    height: calc(2 * 17.6px);
}

.KeyDisplay_lines3__3o6KM{
    height: calc(3 * 17.6px);
}

.KeyDisplay_lines4__3HoFD{
    height: calc(4 * 17.6px);
}

.KeyDisplay_lines5__14Sn3{
    height: calc(5 * 17.6px);
}

.KeyDisplay_lines6__3qg1q{
    height: calc(6 * 17.6px);
}

.KeyDisplay_lines7__z9alV{
    height: calc(7 * 17.6px);
}

.KeyDisplay_lines8__5lZb8{
    height: calc(8 * 17.6px);
}

.KeyDisplay_lines9__1A4FR{
    height: calc(9 * 17.6px);
}

.KeyDisplay_lines10__1EKxf{
    height: calc(10 * 17.6px);
}
.KeyItem_KeyItemContainer__2rAA0{
  
    height: 17.6px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.KeyItem_KeyItem___1Yia{
    flex:1 1;
    font-size: 8px;
    font-style: italic;
    text-align: right;
    padding: 2px;
}
.Content-textPanelContainer{
    width: 50%;
    margin: 16px;
}

.Content-slidePanelContainer{
    width: 50%;
    margin: 16px;
}

.Content-line{
    width: 2px;
    height: 900px;
    margin: 16px;
    background-color: #333333;
}
