.KeyItemContainer{
  
    height: 17.6px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.KeyItem{
    flex:1;
    font-size: 8px;
    font-style: italic;
    text-align: right;
    padding: 2px;
}