.KeyPanel{
    flex:1;
    font-size: xx-small;
}

.TopSpacer{
    height: 41.8px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    color: gray;
 
    /* border-bottom: 0.5px solid gray; */
    
}

.KeySetup{
    /* display: flex;
    flex-direction: column; */
    flex:1;
}

