.TemplatePanel{
    flex:1;
    font-size: xx-small;
}

.TopSpacer{
    height: 41.8px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    color: gray;
 
    /* border-bottom: 0.5px solid gray; */
    
}

.TemplateSetup{
    /* display: flex;
    flex-direction: column; */
    flex:2;
}

.ButtonContainer{
    border-top: 1px dotted #ddd;
  margin: 5px 5px 0 5px;
  padding: 15px 0 0 0;
  
}

.NewSlideButton{
  width: 80px;
  height: 40px;
  background-color: lightsteelblue;
  border-radius: 5%;
  border: none;
  padding: 0;
 
  font-size: 10px;
  color: white;
  text-align: center;
  vertical-align: middle;
  outline: none;

}

.NewSlideButton:focus{
  outline: none;
  border: none;
}

